import { useState, useEffect } from 'react'
import { ethers } from "ethers";
import './App.css'
import deploymentArtifact from './artifacts/TokenDeployer.json'
import BigNumber from "bignumber.js";

const NETWORKS = {
  arbitrum: {
    name: "Arbitrum",
    chainId: 42161,
    contractAddress: "0x191Fe8de59E4470F35612b669b97DA5C9d308197"
  },
  fantom: {
    name: "Fantom",
    chainId: 250,
    contractAddress: "0xad852c2C506F8E90777DC690b1a1Db62d4FD8d6C"
  },
  aurora: {
    name: "Aurora",
    chainId: 1313161554,
    contractAddress: "0x3bf312948138Bd09a891F272a4d36b87B3ab8556"
  }
};

function App() {
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [totalSupply, setTotalSupply] = useState('');
  const [amountToKeep, setAmountToKeep] = useState('');
  const [etherSendAmount, setEtherSendAmount] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');
  const [poolAddress, setPoolAddress] = useState('');
  const [selectedChain, setSelectedChain] = useState('aurora');

  const networkConfig = NETWORKS[selectedChain];

  useEffect(() => {
    // Update background color based on selected network
    if (selectedChain === "arbitrum") {
      document.body.className = "arbitrum";
    } else if (selectedChain === "fantom") {
      document.body.className = "fantom";
    } else if (selectedChain === "avalanche") {
      document.body.className = "avalanche";
    } else if (selectedChain === "aurora") {
      document.body.className = "aurora";
    }
  }, [selectedChain]);


  useEffect(() => {
    async function getChainId() {
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const { chainId } = await provider.getNetwork();
        const { chainId: requestedChainId } = networkConfig;

        if (chainId !== requestedChainId) {
          alert(`Incorrect Network, please switch to ${networkConfig.name}`);
        }
      }
    }

    getChainId();
  }, [networkConfig]);

  useEffect(() => {
    async function connectToContract() {
      if (typeof window.ethereum !== 'undefined') {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(networkConfig.contractAddress, deploymentArtifact.abi, signer);

        contract.on("TokenDeployed", (tokenAddress, name, symbol, totalSupply, amountToKeep, amountToAdd, uniswapV2Pair) => {
          setTokenAddress(tokenAddress);
          setPoolAddress(uniswapV2Pair);
        });
      }
    }

    connectToContract();
  }, [networkConfig]);

  async function createPool(event) {
    event.preventDefault();
    if (typeof window.ethereum !== 'undefined') {
      console.log('ethereum is defined');
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(networkConfig.contractAddress, deploymentArtifact.abi, signer);

      // Check ether balance
      const address = await signer.getAddress();
      const balance = await provider.getBalance(address);
      const formattedBalance = ethers.utils.formatEther(balance);

      if (parseFloat(etherSendAmount) > parseFloat(formattedBalance)) {
        alert(`Insufficient ether balance. You have ${formattedBalance} ether.`);
        return;
      }
      if (parseFloat(amountToKeep) >= parseFloat(totalSupply)) {
        alert(`Amount to keep must be less than totalSupply`);
        return;
      }
      const totalSupplyWithDecimals = new BigNumber(totalSupply).multipliedBy(new BigNumber(10).pow(18)).toFixed();
      const amountToKeepWithDecimals = new BigNumber(amountToKeep).multipliedBy(new BigNumber(10).pow(18)).toFixed();

      const overrides = {
        value: ethers.utils.parseEther(etherSendAmount.toString())
      };

      await contract.deployAndAddLiquidity(name, symbol, totalSupplyWithDecimals, amountToKeepWithDecimals, overrides);

      console.log('transaction successful!');
    }
  }

  function handleNetworkChange(event) {
    setSelectedChain(event.target.value);
    setTokenAddress('');
    setPoolAddress('');
  }

  return (
    <div className="App">
      <div className="form-container">
        <h1 className="heading">Get Rich Quick</h1>
        <h2 className="subheading">Deploy the next big rug pull token in mere seconds!</h2>
        <form className="form" onSubmit={createPool}>
          <div className="form-input">
            <label htmlFor="name">Token Name:</label>
            <input id="name" type="text" value={name} onChange={e => setName(e.target.value)} required />
          </div>

          <div className="form-input">
            <label htmlFor="symbol">Token Symbol:</label>
            <input id="symbol" type="text" value={symbol} onChange={e => setSymbol(e.target.value)} required />
          </div>

          <div className="form-input">
            <label htmlFor="totalSupply">Total Supply:</label>
            <input id="totalSupply" type="text" value={totalSupply} onChange={e => setTotalSupply(e.target.value)} required />
          </div>

          <div className="form-input">
            <label htmlFor="amountToCopy">Amount to Keep:</label>
            <input id="amountToKeep" type="text" value={amountToKeep} onChange={e => setAmountToKeep(e.target.value)} required />
          </div>

          <div className="form-input">
            <label htmlFor="etherSendAmount">Ether to send:</label>
            <input id="etherSendAmount" type="text" value={etherSendAmount} onChange={e => setEtherSendAmount(e.target.value)} required />
          </div>

          <div className="form-input">
            <label htmlFor="chain">Chain:</label>
            <select id="chain" value={selectedChain} onChange={e => handleNetworkChange(e)}>
              {Object.keys(NETWORKS).map(networkName => (
                <option key={networkName} value={networkName}>{NETWORKS[networkName].name}</option>
              ))}
            </select>
          </div>

          <button className="create-pool-btn" type="submit">Create Pool</button>


          {tokenAddress && (
            <div className="output">
              <p>Token Address: {tokenAddress}</p>
              <p>Pool Address: {poolAddress}</p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default App
